<template>
    <pre class="info">
        <span class="col-1"> { </span>
            <span class="col-2"><em class="blue">"author"</em>: <em class="orange">"Kodie Upton"</em>,</span>
            <span class="col-2"><em class="blue">"description"</em>: <em class="orange">"LuminateOne | Developer"</em>,</span>
            <span class="col-2"><em class="blue">"homepage"</em>: <em class="orange">"kodie.dev"</em>,</span>
            <ObjectDisplay label="repository" :info="info.repository"></ObjectDisplay>
            <ObjectDisplay label="engines" :info="info.engines"></ObjectDisplay>
            <ObjectDisplay label="dependencies" :info="info.dependencies"></ObjectDisplay>
            <ObjectDisplay label="devDependencies" :info="info.devDependencies"></ObjectDisplay>
        <span class="col-1">}</span>
    </pre>
</template>

<script>

import ObjectDisplay from './ObjectDisplay'

export default {
    name: 'Info',
    components: {
        ObjectDisplay
    },
    data () {
        return {
            options: {
                repository: true,
                engines: true,
                dependencies: true,
                devDependencies: true
            },
            info: {
                repository: {
                    type: 'Open-source',
                    url: 'https://github.com/kodieupton'
                },
                engines: ['PHP', 'JavaScript', 'HTML5', 'CSS3', 'SQL'],
                dependencies: {
                    laravel: '*',
                    npm: '*',
                    vue: '*',
                    vuex: '*',
                    scss: '*',
                    sass: '*'
                },
                devDependencies: {
                    internet: '763.4 mbps',
                    machine: 'Macbook Pro 13\\" 2020',
                    editor: 'vscode',
                    music: 'Eminem'
                }
            }
        }
    },
    methods: {
        toggleOption (option) {
            this.options[option] = !this.options[option]
        }
    }
}
</script>
