const axios = require('axios')

export default {
    namespaced: true,
    state: {
        updatedData: null,
        upcomingLaunches: [],
        rockets: [],
        launchpads: []
    },
    getters: {
        upcomingLaunches: (state) => {
            return state.upcomingLaunches
        },
        launch: (state) => (id) => {
            return state.upcomingLaunches.find(launch => launch.id === id)
        },
        rocket: (state) => (id) => {
            return state.rockets.find(rocket => rocket.id === id)
        },
        launchpads: (state) => {
            return state.launchpads
        },
        launchpad: (state) => (id) => {
            return state.launchpads.find(launchpad => launchpad.id === id)
        }
    },
    mutations: {
        updateUpcomingLaunches (state, data) {
            state.upcomingLaunches = data
        },
        updateLaunchpads (state, data) {
            state.launchpads = data
        },
        updateRockets (state, data) {
            state.rockets = data
        },
        updateRocket (state, data) {
            const rockets = state.rockets

            const rocketIndex = rockets.findIndex(r => r.id === data.id)
            if (rocketIndex !== -1) {
                state.rockets[rocketIndex] = data
            } else {
                state.rockets.push(data)
            }
        }
    },
    actions: {
        async fetchUpcomingLaunches ({ state, commit }) {
            let upcomingLaunches = state.upcomingLaunches
            if (state.updatedData === null) {
                const response = await axios.get('https://api.spacexdata.com/v5/launches/upcoming')
                upcomingLaunches = response.data
                commit('updateUpcomingLaunches', upcomingLaunches)
            }

            return upcomingLaunches
        },
        async fetchRockets ({ commit }) {
            const response = await axios.get('https://api.spacexdata.com/v4/rockets')
            commit('updateRockets', response.data)
            return response.data
        },
        async fetchRocket ({ commit }, id) {
            const response = await axios.get(`https://api.spacexdata.com/v4/rockets/${id}`)
            commit('updateRocket', response.data)
            return response.data
        },
        async fetchLaunchpads ({ commit }) {
            const response = await axios.get('https://api.spacexdata.com/v4/launchpads')
            commit('updateLaunchpads', response.data)
            return response.data
        }
    }
}
