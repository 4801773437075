<template>
    <nav>
        <div>
            <router-link :to="{name: 'home'}">
                <font-awesome-icon icon="home" />
                <span class="navigation-tip"><span>Home</span></span>
            </router-link>
            <router-link :to="{name: 'portfolio'}">
                <font-awesome-icon icon="suitcase" />
                <span class="navigation-tip"><span>Portfolio</span></span>
            </router-link>
            <!-- <router-link :to="{name: 'contact'}">
                <font-awesome-icon icon="paper-plane" />
                <span class="navigation-tip"><span>Contact Me</span></span>
            </router-link> -->
            <a href="https://twitter.com/Kodiesu" target="_blank">
                <font-awesome-icon :icon="['fab', 'twitter']" />
                <span class="navigation-tip"><span>My Twitter</span></span>
            </a>
            <a href="https://www.linkedin.com/in/kodie-upton-7115a6a2/" target="_blank">
                <font-awesome-icon :icon="['fab', 'linkedin']" />
                <span class="navigation-tip"><span>My Linkedin Profile</span></span>
            </a>
            <a href="https://github.com/kodieupton" target="_blank">
                <font-awesome-icon :icon="['fab', 'github']" />
                <span class="navigation-tip"><span>My Github</span></span>
            </a>
            <a href="https://www.buymeacoffee.com/kodieu" target="_blank">
                <font-awesome-icon :icon="['fa', 'coffee']" />
                <span class="navigation-tip"><span>Buy me a coffee</span></span>
            </a>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Navigation'
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

nav{
    background: #181818;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;

    @media screen and (min-width: 576px) {
        position: sticky;
        top: 0;
        height: 100vh;
    }

    &> div{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: min-content;
        grid-gap: 15px;

        @media screen and (min-width: 576px) {
            grid-template-columns: 1fr;
            grid-template-rows: min-content;
        }
    }

    a{
        width: 100%;
        height: 40px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        color: #ffffff;
        justify-items: center;
        align-items: center;
        position: relative;

        .navigation-tip {
            position: absolute;
            left: 140%;
            z-index: 100;
            background: $brand-color;
            padding: 3px 10px;
            border-radius: 1px 10px 10px 1px;

            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 0.25s linear 1s;

            &::before {
                content: " ";
                position: absolute;
                left: -15px;
                top: 0;
                width: 0;
                height: 0;
                border-top: 14px solid transparent;
                border-bottom: 15px solid transparent;
                border-right: 15px solid $brand-color;
            }

            span {
                white-space: nowrap;
                color: #ffffff;
            }
        }

        &:hover{
            color: #00b7ff;

            .navigation-tip {
                @media screen and (min-width: 576px) {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}
</style>
