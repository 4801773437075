import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as firebase from 'firebase/app'
import 'firebase/analytics'
import VueMeta from 'vue-meta'
import { LMap, LTileLayer, LMarker, LTooltip, LPopup } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faUser, faPaperPlane, faSpinner, faSuitcase, faChevronLeft, faCoffee } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { Icon } from 'leaflet'

const firebaseConfig = {
    apiKey: 'AIzaSyDLSXm_7W2v8zBQpZ-H7mVBJcd-6enXFEI',
    projectId: 'personal-website-81ebc',
    appId: '1:1044492472380:web:265117df4224f60250f339',
    measurementId: 'G-TZLXT53S8V'
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

library.add(faHome, faUser, faPaperPlane, faSpinner, faGithub, faTwitter, faLinkedin, faSuitcase, faChevronLeft, faCoffee)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('l-tooltip', LTooltip)
Vue.component('l-popup', LPopup)

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.config.productionTip = false

Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: false
})

Vue.prototype.$analytics = firebase.analytics()

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
