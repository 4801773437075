<template>
    <div class="col-2">
        <em @click="toggleOption = !toggleOption" class="label blue" :class="{plus: !toggleOption}">"{{ label }}"</em>: {{ object ? '{' : (array ? '[' : '') }} <template v-if="!toggleOption">... </template>
            <template v-if="object">
                <span v-for="(d, l) in info" :key="l" v-show="toggleOption" class="col-1"><em class='blue'>"{{ l }}"</em>: <em class='orange'>"<a v-if="isLink(d)" :href="d" target="_blank" class="orange">{{ d }}</a><template v-else>{{ d }}</template>"<em class="white">,</em></em></span>
            </template>
            <template v-if="array">
                <span v-for="(d, index) in info" :key="`${index}-${d}`" v-show="toggleOption" class="col-1"><em class='orange'>"<a v-if="isLink(d)" :href="d" target="_blank" class="orange">{{ d }}</a><template v-else>{{ d }}</template>"<em class="white">,</em></em></span>
            </template>
        <template v-if="toggleOption">
            <br />
        </template>{{ object ? '}' : (array ? ']' : '') }},
    </div>
</template>

<script>
export default {
    name: 'ObjectDisplay',
    props: [
        'info',
        'label'
    ],
    data () {
        return {
            infoData: null,
            toggleOption: true,
            object: null,
            array: null
        }
    },
    watch: {
        info: {
            immediate: true,
            handler: function (info) {
                if (Array.isArray(info)) {
                    this.array = true
                } else if (typeof info === 'object' && info !== null) {
                    this.object = true
                }
            }
        }
    },
    methods: {
        isLink (value) {
            const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
            return !!pattern.test(value)
        }
    }
}
</script>
