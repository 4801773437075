<template>
    <div class="home">
        <Info></Info>
        <SolarSystem></SolarSystem>
    </div>
</template>

<script>
import Info from '@/components/Info.vue'
import SolarSystem from '@/components/SolarSystem.vue'

export default {
    name: 'home',
    components: {
        Info,
        SolarSystem
    },
    metaInfo: {
        title: 'Home',
        titleTemplate: '%s | Kodie Upton',
        meta: [
            { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { name: 'description', content: 'My simple personal website, that I will build on over time.' }
        ]
    }
}
</script>
