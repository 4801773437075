<template>
    <div id="app">
        <Navigation></Navigation>
        <div class="main-view">
            <router-view/>
        </div>
    </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import * as firebase from 'firebase/app'
import { version } from '../package.json'

export default {
    components: {
        Navigation
    },
    metaInfo: {
        changed (metaInfo) {
            firebase.analytics().setCurrentScreen(metaInfo.title)
            firebase.analytics().logEvent('page_view', {
                page_title: metaInfo.title
            })
            firebase.analytics().logEvent('screen_view', {
                app_name: 'web',
                screen_name: metaInfo.title,
                app_version: version
            })
        }
    }
}
</script>

<style lang="scss">
    @import '@/assets/scss/app.scss';
</style>
