import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/portfolio',
            name: 'portfolio',
            component: () => import(/* webpackChunkName: "portfolio" */ './views/Portfolio.vue')
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue')
        },
        {
            path: '/spacex',
            name: 'spacex',
            component: () => import(/* webpackChunkName: "spacex" */ './views/spacex/UpcomingLaunches.vue')
        },
        {
            path: '/spacex/launch/:launchId',
            name: 'spacex-launch',
            component: () => import(/* webpackChunkName: "spacex" */ './views/spacex/Launch.vue')
        }
    ]
})
